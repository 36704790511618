import React, { useEffect, useState } from "react";
import "./index.css";
import playerIcon from "../../assets/images/page/account/pro-icon.png";
import home from "shared/assets/page/account/home.png";
import star from "../../assets/images/page/account/star.png";
import { Grid } from "@material-ui/core";
import Header from "~/components/Header/Header";
import bannerImg from "../../assets/images/account/banner.png";
import { Row, Col } from "react-bootstrap";
import Footer from "../../components/Footer/Footer";
import { useAuth } from 'shared/auth';
import { useQuery } from 'react-query';
import { fetchVisionaireProfile } from 'shared/react-query/strapiAPI';
import { isNil } from 'lodash';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import { StrapiVideo } from "shared/ui/StrapiVideo"
import { getStrapiAssetURL } from "shared/utils/strapiUtils";
import { PlayCircleOutline } from '@material-ui/icons';
import { Typography } from '@material-ui/core';
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom";
import AccountTabsContent from "shared/sections/account/AccountTabsContent";


const Account = () => {
  const { id: slug } = useParams();
  const [playVideo, setPlayVideo] = useState(false);
  const { auth, profileData, logout } = useAuth();
  const { data, error, isLoading } = useQuery(['visionaireProfile', slug, auth.token], () => fetchVisionaireProfile(slug ?? profileData?.slug, auth.token), { enabled: !isNil(slug ?? profileData?.slug) });
  const history = useHistory();

  if (!data) {
    return <></>
  }
  const images = data?.data.attributes?.displayPhotosAndVideos?.data?.map((e, i) => {
    if (e?.attributes?.mime == "video/mp4") {
      return <div style={{ position: "relative", width: "100%", height: "100%" }} onClick={() => { setPlayVideo(e?.attributes?.url) }}> <StrapiVideo segment={e?.attributes?.url} controls={false} />
        <PlayCircleOutline style={{ position: "absolute", bottom: "35%", left: "50%", transform: "translateX(-50%)", height: "60px", width: "60px" }} />
      </div>
    }
    return (
      <img key={i} src={getStrapiAssetURL(e?.attributes?.url)} className="dynamicimg" />
    )
  })
  return (
    <div className="account-detail">
      <Header />
      <Row>
        <Col sm={12} md={12}>
          <div className="banner-secss">
            <div className="content-sec">
              <h3>ACCOUNT</h3>
            </div>
            <img alt="not found" src={bannerImg} />
          </div>
        </Col>
      </Row>
      <div className="profile-wrap">
        <Grid container spacing={2} style={{ marginBottom: 20 }}>
          <Grid item md={6} style={{ width: "100%" }}>
            <div className="profile-sec">
              <div className="pro">
              <Carousel showArrows={true} showThumbs={false}>
                {images}
              </Carousel>
              </div>
              <div className="pro2">
                <Typography className="userName">{data.data.attributes.firstName} {data.data.attributes?.lastName ?? ""}</Typography>
                <p> <img alt="not found" src={home} />{data.data.attributes.location}</p>
                <p> <img alt="not found" src={star} />{data.data.attributes.title}</p>
                {/* <button>MY SCREEN SAVER</button> */}
              </div>
              <div className="btn-pro">
                  <button onClick={()=>{alert("Feature coming soon.")}}>UPLOAD Image</button>
                  <button onClick={()=>{alert("Feature coming soon.")}}>SET DEFAULT</button>
                  <button onClick={async ()=>{ await logout(); history.push('/');}}>Logout</button>
                </div>
            </div>
          </Grid>
          <Grid item md={2} style={{ width: "100%" }}>
            <div className="profile-sec2">
              <p>METAWHEEL</p>
              <img alt="not found" src={playerIcon} />
            </div>
          </Grid>
          <Grid item md={4} style={{ width: "100%" }}>
            <div className="profile-sec3">
              <button>Progress account profile</button>
              <button>set public profile</button>
            </div>
          </Grid>
        </Grid>
        <AccountTabsContent />
      </div>
      <Footer />
    </div>
  );
};

export default Account;
