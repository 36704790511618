import React, { useState } from 'react';
import Slider from "react-slick";
import startCom from '../../assets/images/start-com.png'
import endCom from '../../assets/images/end-com.png'
import Rating from '@material-ui/lab/Rating';
import './index.scss'

const Index = ({ reviews }) => {
  const [slickautoplay, setslickautoplay] = useState(true);
  let settings = {
    accessibility: true,
    autoplay: slickautoplay,
    dots: false,
    infinite: true,
    autoplaySpeed: 2300,
    speed: 2300,
    slidesToShow: reviews.length > 4 ? 4 : reviews.length,
    slidesToScroll: 1,
    swipeToSlide: true,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        }
      },
      {
        breakpoint: 1305,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <div className="customer_reviews">
      <Slider {...settings}>
        {
          reviews.map((item, idx) => {
            return (
              <div key={idx} className="review-card">
                <div className="card-review">
                  <div className="head-sec">
                    <img alt="not found" src={item.ppic} />
                    <div className="head-info">
                      <p>{item.caption}</p>
                      <h1>{item.customer}</h1>
                      <p style={{ textAlign: 'left' }}>{item?.customerTitle}</p>
                    </div>
                  </div>
                  <div className="star-info">
                    <p>
                      <Rating name="half-rating-read" defaultValue={item.stars} precision={0.5} readOnly />
                    </p>
                    <p>{item.tab}</p>
                  </div>
                  <div className="info2">
                    <img alt="not found" src={startCom} />
                    <p>{item.review}</p>
                    <img alt="not found" src={endCom} className="img2" />
                    <span>{item.date}</span>
                  </div>
                </div>
              </div>
            )
          })}
      </Slider>
    </div>
  )
}

export default Index;