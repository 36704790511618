import React from "react";
import {
  ThemeProvider,
  createTheme,
  makeStyles,
} from "@material-ui/core/styles";
import { QueryClient, QueryClientProvider } from "react-query";
import "./App.scss";
import "./assets/styles/bootstrap.min.css";
import "./assets/styles/responsive.scss";
import "./assets/styles/style.scss";

import _ from "lodash";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { PageRoute, RouteLinks } from "./router/index";
import { AuthProvider } from "shared/auth";
import { testShared } from 'shared';

const theme = createTheme();
const queryClient = new QueryClient();

function App() {
  
  testShared()
  return (
    <ThemeProvider theme={theme}>
        <QueryClientProvider client={queryClient}>
          <AuthProvider>
            <Router>
              <Switch>
                {_.map(RouteLinks, ({ component, path }, key) => {
                  return (
                    <Route
                      exact
                      path={path}
                      key={key}
                      render={(route) => (
                        <PageRoute exact component={component} route={route} />
                      )}
                    />
                  );
                })}
              </Switch>
            </Router>
          </AuthProvider>
        </QueryClientProvider>
    </ThemeProvider>
  );
}

export default App;
