import React, { useState } from 'react';
import './index.css';
const VideoCarousel = ({ slides, setCurrentVideoIndex, currentVideoIndex }) => {

  const handlePrev = () => {
    setCurrentVideoIndex((prev) => (prev === 0 ? slides.length - 1 : prev - 1));
  };

  const handleNext = () => {
    setCurrentVideoIndex((prev) => (prev === slides.length - 1 ? 0 : prev + 1));
  };

  return (
    <div className="video-carousel">
      {/* Video Display */}
      <div className="carousel-video">
        <iframe
          src={slides[currentVideoIndex].src}
          title={`Video ${currentVideoIndex}`}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        />
      </div>

      {/* Navigation Buttons */}
      <button onClick={handlePrev} className="carousel-btn prev-btn">
        <svg xmlns="http://www.w3.org/2000/svg" style={{rotate:"180deg"}}  fill="white" width="18px" height="18px" viewBox="0 0 24 24" id="next" data-name="Flat Color" class="icon flat-color"><path id="primary" d="M18.6,11.2l-12-9A1,1,0,0,0,5,3V21a1,1,0,0,0,.55.89,1,1,0,0,0,1-.09l12-9a1,1,0,0,0,0-1.6Z"/></svg>
      </button>
      <button onClick={handleNext} className="carousel-btn next-btn">
      <svg xmlns="http://www.w3.org/2000/svg" fill="white" width="18px" height="18px" viewBox="0 0 24 24" id="next" data-name="Flat Color" class="icon flat-color"><path id="primary" d="M18.6,11.2l-12-9A1,1,0,0,0,5,3V21a1,1,0,0,0,.55.89,1,1,0,0,0,1-.09l12-9a1,1,0,0,0,0-1.6Z"/></svg>
      </button>
    </div>
  );
};

export default VideoCarousel;
