/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-no-target-blank */
import * as React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { Modal, Popover } from "@material-ui/core";
import lineIcon from "../../assets/images/line-icon.png";
import logo from "../../assets/images/logo.png";
import searchIcon from "../../assets/images/search-icon.png";
import cartIcon from "../../assets/images/cart-icon.png";
import profileIcon from "../../assets/images/profile-icon.png";
import moreIcons from "../../assets/images/more-icons.png";
import "./Header.scss";
import ServiceDropdown from "../service-dropdown";
import { Link } from "react-router-dom";
import { sidemenuRoute } from "../../router/RouteLinks";
import Sidemenu from "./MenuComponents/Sidemenu";
import AppDrawerPopOver from "shared/appDrawer";
import Login from "./Sections/Login";
import { openLoginModal, closeLoginModal } from "shared/utils/commonFunc";
import { getPath } from "~/static/routes";
import { useAuth } from "shared/auth";

const Header = () => {
  const [button, setButton] = React.useState(true);
  const [openDropdown, setOpenDropdown] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(false);
  const [sidemenuOpen, setSidemenuOpen] = React.useState(false);
  const search = useLocation().search;
  const searchParams = new URLSearchParams(search);
  const location = useLocation();
  const { auth, profileData } = useAuth();
  const [loginSuccess, setLoginSuccess] = React.useState(false);

  const history = useHistory();
  const dbLinks = (link) => {
    history.push(link);
  };
  React.useEffect(() => {
    showButton();
    if (searchParams.get("sidemenuopen") === "true") {
      setSidemenuOpen(true);
      dbLinks("/home");
    }
  }, []);
  const handleClickMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  // const { pathname } = useLocation();
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpen = () => {
    if (!auth?.token?.length) {
      openLoginModal(history, location);
    } else {
      history.push(getPath("Account"));
    }
  };

  const handleLoginClose = (reason) => {
    closeLoginModal(history, location);
    setAnchorEl(null);
    if (reason === "login-success") {
      setLoginSuccess(true);
    }
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const showButton = () => {
    if (window.innerWidth <= 960) {
      setButton(false);
    } else {
      setButton(true);
    }
  };
  const toggleSideNav = () => {
    if (document.getElementById("mySidenav").style.width == "250px") {
      document.getElementById("mySidenav").style.width = "0px";
    } else {
      document.getElementById("mySidenav").style.width = "250px";
    }
  };

  window.addEventListener("resize", showButton);

  // React.useEffect(() => {
  //   if (loginSuccess) {
  //     if (profileData?.firstTimeUser === true || !profileData?.firstTimeUser) {
  //       history.push(getPath("Account"));
  //     }
  //   }
  // }, [loginSuccess, profileData]);

  return (
    <>
      <header className="header-area">
        <Sidemenu
          historyPush={(element) => {
            history.push(element);
            setSidemenuOpen(false);
          }}
          routes2={sidemenuRoute}
          sidemenuOpen={sidemenuOpen}
          setSidemenuOpen={(bool) => {
            setSidemenuOpen(bool);
          }}
        />
        <Container fluid>
          <Row className="align-items-center">
            <Col md={3} className="mobile-hide">
              {/* <!-- Logo --> */}
              <a href="#!" className="navbar-brand">
                <img
                  alt="not found"
                  src={lineIcon}
                  onClick={() => setSidemenuOpen(true)}
                />
                <img
                  alt="not found"
                  src={logo}
                  onClick={() => dbLinks("/home")}
                />
              </a>
            </Col>
            <Col md={6} className="mobile-hide">
              <nav className="custom-navbar">
                <div className="navbar-navs">
                  <ul className="navbar-info d-flex align-items-center">
                    <li className="nav-item">
                      <Link to="/about-us">
                        <span className="nav-link">About us</span>{" "}
                        <span className="sr-only">(current)</span>
                      </Link>
                    </li>
                    <li className="nav-item custom-dropdwn">
                      <a className="nav-link" href="/discover">
                        discover
                      </a>
                    </li>
                    <li className="nav-item custom-dropdwn">
                      <a
                        className="nav-link"
                        href="#!"
                        onClick={() => setOpenDropdown(!openDropdown)}
                      >
                        services
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        target="_blank"
                        href="https://sanctuarystore.co.nz/participate"
                      >
                        participate
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        target="_blank"
                        href="https://sanctuarystore.co.nz/prosper"
                      >
                        prosper
                      </a>
                    </li>
                    {!auth.token && <li className="nav-item">
                      <a className="nav-link" href="/get-started">
                        get started
                      </a>
                    </li>}
                  </ul>
                </div>
              </nav>
            </Col>
            <Col md={3} className="mobile-hide">
              <ul className="d-flex justify-content-end align-items-center">
                <li className="nav-item">
                  <a href="#!" className="nav-link">
                    <img alt="not found" src={searchIcon} />
                  </a>
                </li>
                <li className="nav-item">
                  <a href="#!" className="nav-link">
                    <img alt="cartIcon" src={cartIcon} />
                  </a>
                </li>
                <li className="nav-item" onClick={handleOpen}>
                  <a className="nav-link">
                    <img alt="not found" src={profileIcon} />
                  </a>
                </li>
                <li className="nav-item" onClick={handleClickMenu}>
                  <a className="nav-link">
                    <img alt="not found" src={moreIcons} />
                  </a>
                </li>
              </ul>
            </Col>

            <Col md={12} className="desk-hide">
              <div className="mobile-menu">
                <div
                  onClick={() => {
                    console.log("clicked!!");
                    setSidemenuOpen(true);
                  }}
                >
                  <img
                    alt="not found"
                    src={lineIcon}
                    style={{ width: "50px" }}
                  />
                </div>
                <a href="#!" className="navbar-brand">
                  <img
                    alt="not found"
                    src={logo}
                    onClick={() => dbLinks("/home")}
                  />
                </a>
                <ul className="d-flex justify-content-end align-items-center">
                  <li className="nav-item">
                    <a href="#!" className="nav-link">
                      <img alt="cartIcon" src={cartIcon} />
                    </a>
                  </li>
                  <li className="nav-item" onClick={handleOpen}>
                    <a className="nav-link">
                      <img alt="not found" src={profileIcon} />
                    </a>
                  </li>
                  <li className="nav-item" onClick={handleClickMenu}>
                    <a href="#!" className="nav-link">
                      <img alt="not found" src={moreIcons} />
                    </a>
                  </li>
                </ul>
              </div>
              <div id="mySidenav" className="sidenav">
                <a href="#!" className="closebtn" onClick={toggleSideNav}>
                  &times;
                </a>
                <Link to="/about-us">
                  <span className="nav_link">About us</span>{" "}
                </Link>
                <a href="/discover">discover</a>
                <a
                  href="#!"
                  onClick={() => {
                    setOpenDropdown(!openDropdown);
                    toggleSideNav();
                  }}
                >
                  services
                </a>
                <a href="#!">participate</a>
                <a target="_blank" href="https://sanctuarystore.co.nz/prosper">
                  prosper
                </a>
                <a href="/booking-plans">get started</a>
              </div>
            </Col>
          </Row>
        </Container>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <AppDrawerPopOver />
        </Popover>
        {openDropdown && <ServiceDropdown />}
      </header>
      <Modal
        open={location.search.includes("?login")}
        onClose={handleLoginClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Login closeLoginModal={handleLoginClose} />
      </Modal>
    </>
  );
};

export default Header;
