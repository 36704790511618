import React from "react";
import BeComeButton from "../../components/BeComeButton/BeComeButton";
import FormBuilder from 'shared/formBuilder';
import {register} from 'shared/formStructures/register';
import {useCreateCustomer} from 'shared/react-query/hooks';
import "./VisionaireForm.scss";
const VisionaireForm = ({name, email}) => {
 
  const modifiedRegister = register(name, email);
  const customerCreate = useCreateCustomer();


  return (
    <>
      <div className="form-section">
        <h1>BECOME A VISIONAIRE</h1>
        <BeComeButton />
        <div className="login-form">
        <FormBuilder
                  xs={12}
                  spacing={2}
                  justify="center"
                  structure={modifiedRegister}
                  onSubmit={(values) => {
                    return customerCreate
                      .mutateAsync({
                        input: {
                          email: values.email,
                          password: values.password,
                          firstName: values.firstName,
                          lastName: values.lastName,
                          acceptsMarketing: values.tacCheckbox,
                        },
                      })
                      .then((res) => {
                        return new Promise((resolve, reject) => {
                          setTimeout(() => {
                            if(res.customerCreate.userErrors.length){
                              resolve({error: res.customerCreate.userErrors.reduce((acc,cv)=>{
                                return `${cv.message}` + ` ${acc}`
                              },'')});  
                            }else{
                              resolve({info: 'Signup successful. <br/><div style="display:flex;width:100%;justify-content: center;gap: 10px;color: grey !important;!i;!;"><a style="text-decoration: underline;color: grey;" href="/?login">Sign in</a><br><a style="text-decoration: underline;color: grey;" href="/">Homepage</a></div>'});
                            }
                          }, 300);
                        });
                      });
                  }}
                />
        </div>
      </div>
    </>
  );
};

export default VisionaireForm;
